import { onceEach } from "./once.js";

/* This looks for all select controls with [data-navigation-select], and when it is chaged by the
 * user, the page navigates to the selected option's value. */
const scan = (document) => {
  onceEach(
    document.querySelectorAll("[data-navigation-select] select"),
    "navigation-select",
    (select) => {
      const initialValue = select.value;

      select.addEventListener("change", (ev) => {
        /* be wary of the back-forward cache: when we leave this page, the select should have the same
         * selected item as when the page was loaded, or pressing "back" will show the selection which
         * _caused_ the navigation, not the one corresponding to the page. */
        const nextPage = select.value;
        select.value = initialValue;
        location.assign(nextPage);
      });
    }
  );
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
