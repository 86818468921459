import { onceEach } from "./once.js";

import tablesort from "tablesort";
import "tablesort/src/sorts/tablesort.number.js";
import "tablesort/src/sorts/tablesort.date.js";

const scan = (document) => {
  onceEach(document.querySelectorAll("table[data-sortable]"), "data-sortable", (e) => {
    tablesort(e, {});

    /* If the immediate child is an A, give it an HREF attribute to be in the taborder and have
       it emit "click" */
    e.querySelectorAll("th > a:not([href])").forEach((a) => {
      a.href = "";
      a.addEventListener("click", (ev) => {
        /* don't navigate to "" */
        ev.preventDefault();
      });
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
