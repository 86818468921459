import { getCssSelector } from "css-selector-generator";

class PartialReplaceEvent extends Event {
  /*
    - `target` is the element with [data-partial] specified.
    - `container` is its parent element.  This is to allow for querySelector on the element
      to match the [data-partial] element itself without special logic.
  */
  constructor({ partial, instance, element, container }) {
    super("partial:replace");
    Object.assign(this, { partial, instance, element, container });
  }
}

const getSelectionState = (element) => {
  if (element && "selectionStart" in element) {
    return {
      start: element.selectionStart,
      end: element.selectionEnd,
      direction: element.selectionDirection,
    };
  }

  return null;
};

const applySelectionState = (element, state) => {
  if (state && element && "selectionStart" in element) {
    try {
      element.setSelectionRange(state.start, state.end, state.direction);
    } catch (e) {
      if (element.type === "number") {
        element.select();
      }
    }
  }
};

const replaceInside = (element, html, activeElement) => {
  const selector = getCssSelector(activeElement);
  const state = getSelectionState(activeElement);

  element.innerHTML = html;

  if (selector && !document.contains(activeElement)) {
    const replacement = document.querySelector(selector);
    if (replacement) {
      replacement.focus();
      applySelectionState(replacement, state);
    }
  }
};

const replaceOutside = (element, html) => {
  element.innerHTML = html;
};

export const selectorForPartial = (partial, { instance }) => {
  let selector = `[data-partial="${partial}"]`;

  if (instance !== undefined) {
    selector = `${selector}[data-instance="${instance}"]`;
  }

  return selector;
};

export const findPartial = (partial, { instance, element = document } = {}) => {
  return element.querySelector(selectorForPartial(partial, { instance }));
};

export const findPartials = (partial, { instance, element = document } = {}) => {
  return element.querySelectorAll(selectorForPartial(partial, { instance }));
};

export const replacePartial = ({ partial, instance, html, optional = false } = {}) => {
  const selector = selectorForPartial(partial, { instance });
  const targets = document.querySelectorAll(selector);
  if (targets.length === 0 && !optional) {
    console.error(`replacePartial: element not found: ${selector}`);
    return;
  }

  targets.forEach((target) => {
    const activeElement = document.activeElement;

    if (target.contains(activeElement)) {
      replaceInside(target, html, activeElement);
    } else {
      replaceOutside(target, html, activeElement);
    }

    document.dispatchEvent(
      new PartialReplaceEvent({
        partial,
        instance,
        element: target,
        container: target.parentElement,
      })
    );
  });

  return targets;
};

/* Outside of production scan for invalid [data-partial] and [data-instance] attributes */
if (process.env.NODE_ENV !== "production") {
  const debugScan = (document) => {
    document.querySelectorAll("[data-partial]").forEach((partialElement) => {
      const { partial, instance } = partialElement.dataset;
      if (!partial.match(/^[a-z_]+\/[a-z_]+(\/[a-z_]+)?$/)) {
        throw new Error(`data-partial attribute not fully qualified.`, { partial });
      }

      if (instance !== undefined && instance.length === 0) {
        throw new Error(`data-instance attribute invalid`, { partial, instance });
      }
    });
  };

  document.addEventListener("DOMContentLoaded", () => debugScan(document));
  document.addEventListener("partial:replace", (ev) => debugScan(ev.container));
}
