/* Toggle mobile menu when clicked */

import { onceEach } from "./once.js";

const scan = (document) => {
  onceEach(document.querySelectorAll("[data-navbar-burger]"), "mobile-menu", (el) => {
    el.addEventListener("click", () => {
      el.classList.toggle("is-active");

      document.querySelectorAll(el.dataset.target).forEach((target) => {
        target.classList.toggle("is-active", el.classList.contains("is-active"));
      });
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
