import { forceMobile } from "./force_mobile.js";

export const RemoteModal = {
  template: `
    <aside class="modal is-active remote-modal">
      <div class="modal-background" data-cancels></div>
      <div class="modal-content">
        <div class="box" data-body>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" data-cancels></button>
    </aside>
  `,

  updateElement(element, html) {
    const body = element.querySelector("[data-body]");
    while (body.firstElementChild) {
      body.firstElementChild.remove();
    }

    body.insertAdjacentHTML("afterbegin", html);
    this.bindDynamicEvents(element);

    const autoFocusElement = element.querySelector("[autofocus]");
    if (autoFocusElement) {
      setTimeout(() => autoFocusElement.focus(), 0);
    }

    return body;
  },

  locate() {
    return document.querySelector(".remote-modal");
  },

  bindDynamicEvents(frame) {
    frame.querySelectorAll("[data-cancels]").forEach((e) => {
      if (!e.eventsBound) {
        e.eventsBound = true;
        e.addEventListener("click", () => this.dismiss());
      }
    });
  },

  createFrame() {
    /* This ensures we get a blur on anything (e.g., dropdowns) which may be open*/
    document.body.click();

    const div = document.createElement("div");
    div.insertAdjacentHTML("afterbegin", RemoteModal.template);

    const frame = div.firstElementChild;
    this.bindDynamicEvents(frame);
    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        ev.preventDefault();
        this.dismiss();
      }
    });

    frame.querySelector(".modal-content").classList.add("animate__animated", "animate__fadeIn");

    return frame;
  },

  update({ html }) {
    let frame = this.locate();
    if (frame) {
      this.updateElement(frame, html);
      return frame;
    }
  },

  create({ html }) {
    const frame = this.createFrame();
    this.updateElement(frame, html);
    document.body.appendChild(frame);
    this.mobileLock = forceMobile();
    return frame;
  },

  createOrUpdate({ html }) {
    return this.update({ html }) || this.create({ html });
  },

  dismiss() {
    const existing = this.locate();
    if (this.mobileLock) {
      this.mobileLock.restore();
      this.mobileLock = null;
    }

    if (existing) {
      existing.remove();
    }
  },
};
