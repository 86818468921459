import { onceEach } from "./once.js";

const scan = (document) => {
  onceEach(
    document.querySelectorAll("div[data-growing-textarea]"),
    "growing-textarea",
    (element) => {
      const textarea = element.querySelector("textarea");

      const update = () => {
        element.dataset.replicatedValue = textarea.value;
      };

      update();
      textarea.addEventListener("input", update);
    }
  );
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
