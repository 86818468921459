import { onceEach } from "./once.js";

const scan = (document) => {
  onceEach(
    document.querySelectorAll(".control a.show-password-button"),
    "show-password-button",
    (toggleButton) => {
      const control = toggleButton.closest(".control");
      control.classList.add("has-show-password-button");

      const passwordInput = control.querySelector("input[type=password]");

      toggleButton.addEventListener("click", () => {
        if (passwordInput.type == "password") {
          passwordInput.type = "text";
          control.classList.add("password-is-shown");
        } else {
          passwordInput.type = "password";
          control.classList.remove("password-is-shown");
        }
        passwordInput.focus();
      });
    }
  );
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
