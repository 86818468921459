import { onceEach } from "./once.js";

const createArrow = (container) => {
  const arrow = document.createElement("div");

  arrow.classList.add("scroll-hint-arrow");
  arrow.innerHTML = `
    <span class="icon is-large">
      <i class="fa fa-2x fa-arrow-right"></i>
    </span>
  `;

  container.appendChild(arrow);
  return arrow;
};

const waitForAnimation = async (element, className) => {
  return new Promise((resolve) => {
    element.classList.add(className);
    element.addEventListener(
      "animationend",
      () => {
        element.classList.remove(className);
        resolve();
      },
      { once: true }
    );
  });
};

/* "visible" meaning "scrolled into viewport" */
const waitUntilVisible = (e, callback) => {
  /* Guard against divide-by-zero */
  const scrollHeight = e.scrollHeight > 0 ? e.scrollHeight : 1;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.some((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(e);
          callback();
          return true;
        }
      });
    },
    {
      root: document,
      /* 100px from viewport to make sure the arrow is visible, if < 100px, just half */
      threshold: scrollHeight > 100.0 ? 100.0 / scrollHeight : 0.5,
    }
  );

  observer.observe(e);
};

const startBounce = async (container) => {
  const arrow = createArrow(container);
  await waitForAnimation(arrow, "bouncing");
  await waitForAnimation(arrow, "fading");
};

const scan = (document) => {
  onceEach(document.querySelectorAll("[data-scroll-hint]"), "scroll-hint", (e) => {
    waitUntilVisible(e, () => {
      if (e.scrollWidth > e.clientWidth) {
        setTimeout(() => startBounce(e), 1000.0);
      }
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
