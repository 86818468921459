/* This file manages the "popup" alert views which are embedded in the layout. */

import { markAsReadUserAlertPath, statsUserAlertsPath } from "routes";

class AlertCountChangeEvent extends Event {
  constructor(attributes) {
    super("alert-count:change");
    Object.assign(this, attributes);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const alerts = document.querySelector("section.alerts");

  if (alerts === null) {
    return;
  }

  const postAlertDismissal = async (alert) => {
    const jsonRequestHeaders = {
      "Content-Type": "application/json",
    };

    await fetch(markAsReadUserAlertPath(window.currentUser.id, alert.dataset.alertId), {
      method: "POST",
      headers: jsonRequestHeaders,
    });

    const result = await fetch(statsUserAlertsPath(window.currentUser.id), {
      headers: jsonRequestHeaders,
    });

    document.dispatchEvent(new AlertCountChangeEvent(await result.json()));
  };

  alerts.querySelectorAll(".alert").forEach((alert) => {
    const button = alert.querySelector("[data-dismiss]");

    button.addEventListener("click", () => {
      alert.classList.add("animate__animated", "animate__fadeOutUp", "animate__faster");
      alert.addEventListener("animationend", () => {
        alert.remove();
        if (!alerts.querySelector(".alert")) {
          alerts.remove();
        }
        postAlertDismissal(alert);
      });
    });
  });
});

/* Updates any elements on page with [data-alert-count] or [data-if-alert] */
document.addEventListener("alert-count:change", (ev) => {
  document.querySelectorAll("[data-alert-count]").forEach((el) => {
    el.innerText = ev.unreadAlertCount;
  });

  document.querySelectorAll("[data-if-alert]").forEach((el) => {
    if (ev.unreadAlertCount > 0) {
      el.classList.remove("is-hidden");
    } else {
      el.classList.add("is-hidden");
    }
  });
});
