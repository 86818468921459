/* This module fires `breakpoint:change` events on document when the bulma breakpoint changes. */

class BreakpointChangeEvent extends Event {
  constructor({ name, matches, breakpoints }) {
    super("breakpoint:change");
    Object.assign(this, { name, matches, breakpoints });
  }
}

/* These are the bulma default breakpoints */
const bp = {
  mobile: 768,
  tablet: 769,
  desktop: 1024,
  widescreen: 1216,
  fullhd: 1408,
};

/* these are derived from definitions in `bulma/sass/utilities/mixins.sass` */
const mediaQueryStrings = {
  "mobile": `(max-width: ${bp.mobile}px)`,
  "tablet": `(min-width: ${bp.tablet}px)`,
  "tablet-only": `(min-width: ${bp.tablet}px) and (max-width: ${bp.desktop - 1}px)`,
  "touch": `(max-width: ${bp.desktop - 1}px)`,
  "desktop": `(min-width: ${bp.desktop}px)`,
  "desktop-only": `(min-width: ${bp.desktop}px) and (max-width: ${bp.widescreen - 1}px)`,
  "until-widescreen": `(max-width: ${bp.widescreen - 1}px)`,
  "widescreen": `(min-width: ${bp.widescreen}px)`,
  "widescreen-only": `(min-width: ${bp.widescreen}px) and (max-width: ${bp.fullhd - 1}px)`,
  "until-fullhd": `(max-width: ${bp.fullhd - 1}px)`,
  "fullhd": `(min-width: ${bp.fullhd}px)`,
};

/* { "mobile": false, "tablet": false, ... } */
export const breakpoints = Object.fromEntries(
  Object.keys(mediaQueryStrings).map((name) => [name, false])
);

/* Register watchers for the media query and dispatch initial events. */
document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    Object.entries(mediaQueryStrings).forEach(([name, definition]) => {
      const mediaQuery = window.matchMedia(`screen and ${definition}`);
      mediaQuery.addEventListener("change", (event) => {
        if (breakpoints[name] == event.matches) {
          // only emit events when it actually changes
          return;
        }

        breakpoints[name] = event.matches;
        document.dispatchEvent(
          new BreakpointChangeEvent({ name, breakpoints, matches: event.matches })
        );
      });

      if (mediaQuery.matches) {
        breakpoints[name] = true;
      }

      document.dispatchEvent(
        new BreakpointChangeEvent({ name, breakpoints, matches: breakpoints[name] })
      );
    });
  }, 0);
});
