/*
  `once` and `onceEach` allows performing an action ONCE on an Element or Object.

  For example:

    onceEach(document.querySelectorAll("[data-explode]"), "explode", (e) => {
      e.addEventListener(...);
    });

  The callback will only be invoked the first time this code is executed on the element.
  This allows you to ensure that adding event listeners or mutating state happens once.

  This is meant to replace code like this:

    const x = document.querySelector(".some-widget");
    if (! x._something_done) {
      x._something_done = true;
      x.doSomething()
    }

  onceEach accepts a collection, and returns an array of newly-processed elements.
  once accepts a single element, and returns the element if it was processed, or null otherwise
*/
export const once = (element, key, callback) => {
  element._onceKeys ??= new Set();

  if (element._onceKeys.has(key)) {
    return null;
  }

  element._onceKeys.add(key);
  callback(element);

  if (process.env.NODE_ENV !== "production") {
    /* In development, make it easy to identify in devtools */
    if (element instanceof Element) {
      element.setAttribute("data-once-keys", Array.from(element._onceKeys).join(" "));
    }
  }

  return element;
};

/* applies once() on a collection, yielding the members which are still applicable */
export const onceEach = (collection, ...args) => {
  return Array.from(collection)
    .map((element) => once(element, ...args))
    .filter((x) => x !== null);
};
