/* currentUnloadHandler is null unless there is unsaved data being watched. */
import { currentUnloadHandler } from "./warn_unsaved_changes.js";

const triggerSelectors = [
  /* Can mark specific elements... */
  "a[data-warn-unsaved-trigger]",
  "button[data-warn-unsaved-trigger]",
  "form[data-warn-unsaved-trigger]",

  /* or a containing element */
  "[data-warn-unsaved-trigger] a[data-remote]",
  "[data-warn-unsaved-trigger] button[data-remote]",
  "[data-warn-unsaved-trigger] form[data-remote]",
].join(", ");

export const warnUnsavedTrigger = (ev) => {
  if (currentUnloadHandler) {
    /* Pass an interface-compatible Event-like parameter to the unload handler */
    const result = currentUnloadHandler({
      returnValue: null,
      preventDefault() {},
    });

    if (window.confirm(result)) {
      return true;
    }

    ev.stopPropagation();
    ev.preventDefault();
    return false;
  }

  return true;
};

const scan = (document) => {
  const triggers = document.querySelectorAll(triggerSelectors);

  triggers.forEach((element) => {
    if (element._warnUnsavedTrigger) {
      return;
    }
    element._warnUnsavedTrigger = true;
    element.setAttribute("warn-unsaved-trigger-marked", true);

    const eventName = element.nodeName === "FORM" ? "submit" : "click";
    element.addEventListener(eventName, (ev) => warnUnsavedTrigger(ev), { capture: true });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
