export const ifView = (viewName, callback = () => {}) => {
  const predicates = viewName instanceof Array ? viewName : [viewName];
  const currentView = document.documentElement.dataset.view;

  const matches = predicates.some((predicate) => {
    if (predicate instanceof RegExp) {
      return predicate.test(currentView);
    } else if (typeof predicate === "string") {
      return predicate === currentView;
    } else if (typeof predicate === "function") {
      return predicate(currentView);
    }
  });

  if (matches) {
    callback();
    return true;
  }

  return false;
};

export const onViewLoad = (viewName, callback) => {
  if (["complete", "interactive"].includes(document.readyState)) {
    ifView(viewName, callback);
  } else {
    document.addEventListener("DOMContentLoaded", () => ifView(viewName, callback));
  }
};
