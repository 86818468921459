export const Highlighter = {
  highlight(element, { color = "rgba(250, 250, 102, 0.8)", duration = 1000.0 } = {}) {
    const originalColor = getComputedStyle(element).backgroundColor;

    element.animate(
      {
        backgroundColor: [color, color, originalColor],
      },
      duration
    );
  },
};
