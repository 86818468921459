/* When keyboard editing, a date element's "change" event acts more like "input" event.
 * This method installs handlers which track the typing state, making it more like other
 * "change" events.
 */

export const onDateChange = (input, callback) => {
  let keyDown = false;
  let typed = false;

  const fire = () => {
    keyDown = false;
    typed = false;
    callback();
  };

  /* The input is marked keydown during the process of a keypress.
   * "blur" and "change" ignore events in this state. */
  input.addEventListener("keydown", () => {
    keyDown = true;
    typed = true;
  });

  input.addEventListener("keyup", (ev) => {
    keyDown = false;

    if (ev.key === "Enter") {
      fire();
    }
  });

  input.addEventListener("blur", () => {
    if (typed) {
      fire();
    }
  });

  input.addEventListener("change", () => {
    if (!keyDown) {
      /* This should only be in response to non-keypress events,
       * like using the picker. */
      fire();
    }
  });
};
