import { mapKeys, camelCase } from "lodash-es";

/* Get us JS-flavor attributes instead of Rails flavor */
const camelizeKeys = (obj) => mapKeys(obj, (_, k) => camelCase(k));

class StashedFileProgressEvent extends Event {
  constructor(attributes) {
    super("stashed-file:progress");
    Object.assign(this, attributes);
  }
}

class StashedFileStartEvent extends Event {
  constructor(attributes) {
    super("stashed-file:start");
    Object.assign(this, attributes);
  }
}

class StashedFileReadyEvent extends Event {
  constructor(attributes) {
    super("stashed-file:ready");
    Object.assign(this, attributes);
  }
}

class StashedFileNetworkErrorEvent extends Event {
  constructor({ exception }) {
    super("stashed-file:network-error");
    Object.assign(this, { exception });
  }
}

class StashedFileErrorEvent extends Event {
  constructor(attributes) {
    super("stashed-file:error");
    Object.assign(this, attributes);
  }
}

export const StashedFile = {
  pollRate: 1500,

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  fireEvent(ev) {
    document.dispatchEvent(ev);
  },

  async fetchInfo(url) {
    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
      },
    });

    if (!(response.status >= 200 && response.status < 300)) {
      throw new Error(`bad HTTP response: ${response.status}`);
    }

    return camelizeKeys(await response.json());
  },

  initiateClientDownload(url, fileName) {
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("rel", "noopener");
    document.body.appendChild(a);
    a.click();
    a.remove();
  },

  async download({ path }) {
    let firstFetch = true;
    for (;;) {
      let response;
      try {
        response = await this.fetchInfo(path);
      } catch (error) {
        this.fireEvent(new StashedFileNetworkErrorEvent({ exception: error }));
        throw error;
      }

      if (firstFetch) {
        this.fireEvent(new StashedFileStartEvent(response));
        firstFetch = false;
      }

      if (response.status === "ready") {
        this.fireEvent(new StashedFileProgressEvent(response));

        if (response.downloadUrl !== null) {
          this.initiateClientDownload(response.downloadUrl, response.fileName);
        }

        this.fireEvent(new StashedFileReadyEvent(response));
        return response;
      } else if (response.status === "error") {
        this.fireEvent(new StashedFileErrorEvent(response));
        throw new Error(response.errorMessage);
      } else if (response.status === "processing") {
        this.fireEvent(new StashedFileProgressEvent(response));
      }

      await this.sleep(this.pollRate);
    }
  },
};
