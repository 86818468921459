export const forceMobile = () => {
  if (document.body.classList.contains("is-modern")) {
    /* in modern layouts, we don't have to do anything */
    return {
      restore() {
        /* No-op. */
      },
    };
  } else {
    const viewport = document.querySelector("meta[name=viewport]");
    const saved = viewport.getAttribute("content");
    viewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    );

    return {
      restore() {
        viewport.setAttribute("content", saved);
      },
    };
  }
};
