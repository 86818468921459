/**
 * Handles bulma dropdowns that are marked with "data-auto-dropdown".
 *
 * - Links or buttons in the dropdown menu will dismiss the dropdown.
 * - Clicking outside the dropdown menu will dismiss the dropdown.
 */
import { once } from "./once.js";
import { breakpoints } from "./breakpoint_events.js";

const dropdownSelector = "div.dropdown[data-auto-dropdown]";
const triggerSelector = ".dropdown-trigger";
const itemSelector = "a.dropdown-item";

/* set of currently expanded dropdowns */
let expanded = new Set();

const show = (element) => {
  element.classList.add("is-active");
  expanded.add(element);
};

const dismiss = (element) => {
  element.classList.remove("is-active");
  expanded.delete(element);
};

const scan = (root) => {
  /* Watch window click events to dismiss all dropdowns */
  once(document.body, "auto-dropdown", () => {
    document.body.addEventListener("click", (ev) => {
      if (!ev.target.closest(dropdownSelector)) {
        expanded.forEach((element) => dismiss(element));
      }
    });
  });

  /* locate dropdowns tagged with data-auto-dropdown */
  root.querySelectorAll(dropdownSelector).forEach((dropdownElement) => {
    once(dropdownElement, "auto-dropdown", () => {
      dropdownElement.querySelector(triggerSelector).addEventListener("click", () => {
        if (dropdownElement.dataset["autoDropdown"] == "hover" && !breakpoints["mobile"]) {
          // only handle click events for 'hover' dropdowns on mobile
          return;
        }

        if (dropdownElement.classList.contains("is-active")) {
          dismiss(dropdownElement);
        } else {
          show(dropdownElement);
        }
      });

      if (dropdownElement.dataset["autoDropdown"] == "hover") {
        // add 'hoverable' dropdowns except on mobile where they will be 'click'
        dropdownElement.querySelector(triggerSelector).addEventListener("mouseenter", () => {
          if (!breakpoints["mobile"]) {
            show(dropdownElement);
          }
        });

        dropdownElement.addEventListener("mouseleave", () => {
          if (!breakpoints["mobile"]) {
            dismiss(dropdownElement);
          }
        });
      }

      dropdownElement.querySelectorAll(itemSelector).forEach((menuItem) => {
        menuItem.addEventListener("click", () => dismiss(dropdownElement));
      });
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
