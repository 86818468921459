import { breakpoints } from "./breakpoint_events.js";

/* Bulma structure for a button with an icon and caption */
const captionSelector = ".button .icon+span.is-hidden-mobile:not(.icon)";

/* button elements which no longer match the selector because the inner caption
   span has been removed. */
let watchList = [];

const handleButton = (button, captionElement) => {
  if (!document.contains(button)) {
    /* Don't hold a ref */
    watchList.splice(watchList.indexOf(button), 1);
    return;
  } else if (breakpoints["mobile"] && button._removedCaption === undefined) {
    button.setAttribute("aria-label", captionElement.textContent);
    button._removedCaption = captionElement;
    captionElement.remove();
    watchList.push(button);
  } else if (!breakpoints["mobile"] && button._removedCaption !== undefined) {
    button.appendChild(button._removedCaption);
    button._removedCaption = undefined;
    watchList.splice(watchList.indexOf(button), 1);
  }
};

const scan = (document) => {
  /* DOM elements which still have their caption displayed */
  document.querySelectorAll(captionSelector).forEach((el) => {
    handleButton(el.closest(".button"), el);
  });

  /* And buttons we've already hid the caption, these no longer match the selector */
  /* handleButton mutates watchList, so iterate a copy */
  [...watchList].forEach((button) => handleButton(button, button._removedCaption));
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
document.addEventListener("breakpoint:change", (ev) => {
  if (ev.name === "mobile") {
    scan(document);
  }
});
