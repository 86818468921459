export const Undo = {
  itemTemplate: `
    <li class="box">
      <a data-link data-remote="true" data-method="post">
        <span class="icon"><i class="fas fa-xs fa-undo"></i></span>
        <span data-message></span>
      </a>
      <a data-close>
        <span class="icon"><i class="fas fa-xs fa-times-circle"></i></span>
      </a>
    </li>
  `,
  actions: [],

  expireAction(action) {
    action.expires = 0;
    this.checkUpdate();
  },

  clear() {
    this.actions.forEach((action) => this.expireAction(action));
  },

  pruneExpired() {
    const expired = this.actions.filter((action) => action.expires < Date.now());
    this.actions = this.actions.filter((action) => !expired.includes(action));
    return expired;
  },

  createFrame() {
    const frame = document.createElement("ul");
    frame.setAttribute("id", "undo-panel");
    frame.classList.add("animate__animated", "animate__fadeInLeft");
    document.body.appendChild(frame);
    return frame;
  },

  createUndoElement(action) {
    const div = document.createElement("div");
    div.insertAdjacentHTML("afterbegin", this.itemTemplate);

    const li = div.firstElementChild;

    const link = li.querySelector("[data-link]");
    link.setAttribute("href", action.link || "#");

    const message = li.querySelector("[data-message]");
    message.insertAdjacentText("afterbegin", action.message ? `Undo: ${action.message}` : "Undo");

    link.addEventListener("click", () => this.expireAction(action));
    li.querySelector("[data-close]").addEventListener("click", () => this.expireAction(action));

    return li;
  },

  startWatching() {
    this.interval = this.interval || setInterval(() => this.checkUpdate(), 1000);
  },

  tryToSleep() {
    if (this.frame && this.actions.length === 0) {
      clearInterval(this.interval);
      this.interval = null;

      this.frame.remove();
      this.frame = null;
    }
  },

  checkUpdate() {
    this.pruneExpired().forEach((action) => {
      action.element.classList.add("animate__animated", "animate__fadeOutLeft");
      action.element.addEventListener("animationend", () => {
        action.element.remove();
        this.tryToSleep();
      });
    });
  },

  push({ message, link, method, expiresIn = 1000 * 15 } = {}) {
    this.frame = this.frame || this.createFrame();
    this.startWatching();

    const action = { message, link, method, expires: Date.now() + expiresIn };
    action.element = this.createUndoElement(action);
    this.actions.push(action);

    this.frame.prepend(action.element);
  },
};
