/* global grecaptcha */

const recaptchaElementFor = (form) => form.querySelector("div.recaptcha");
const resultElementFor = (form) => form.querySelector("input[name=recaptcha-result]");

const setResult = (form, value) => {
  resultElementFor(form).value = value;

  if (value !== "") {
    form.dispatchEvent(new Event("captcha-verified"));
  }
};

const formSubmitted = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();

  /* starts the challenge (if needed) */
  const form = ev.target;
  grecaptcha.execute(recaptchaElementFor(form));
};

const captchaVerified = (ev) => {
  const form = ev.target;
  form.removeEventListener("submit", formSubmitted);

  form.submit();

  /* Reset state */
  resultElementFor(form).value = "";
  form.addEventListener("submit", formSubmitted);
  grecaptcha.reset(recaptchaElementFor(form));
};

window.recaptchaLoaded = () => {
  document.querySelectorAll("div.recaptcha").forEach((el) => {
    const form = el.closest("form");
    form.addEventListener("submit", formSubmitted);
    form.addEventListener("captcha-verified", captchaVerified);

    grecaptcha.render(el, {
      "sitekey": el.dataset.siteKey,
      "callback": (...args) => setResult(form, ...args),
      "error-callback": () => setResult(form, ""),
      "expired-callback": () => setResult(form, ""),
      "badge": "bottomleft",
    });
  });
};
