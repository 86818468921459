/*
  Marking a form "[data-single-submit]" means it can only be submitted once.  On submit, the
  fields are disabled if the form has wrapped them in a <fieldset>, and all submit buttons
  are moved to is-loading state.
*/

import { onceEach } from "./once.js";

const buttonsForForm = (form) => {
  return form.querySelectorAll("input[type=submit], button[type=submit]");
};

const disableForm = (form) => {
  form.setAttribute("is-submitting", "");
  buttonsForForm(form).forEach((button) => {
    button.classList.add("is-loading");
    button.setAttribute("disabled", "");
  });
};

const enableForm = (form) => {
  form.removeAttribute("is-loading");

  buttonsForForm(form).forEach((button) => {
    button.classList.remove("is-loading");
    button.removeAttribute("disabled");
  });
};

const scan = (document) => {
  onceEach(document.querySelectorAll("form[data-single-submit]"), "single-submit", (form) => {
    form.addEventListener("submit", (ev) => {
      if (form.getAttribute("is-submitting")) {
        ev.preventDefault();
        return;
      }

      disableForm(form);
    });

    /* rails' `remote: true` will fire this event on the form. */
    form.addEventListener("ajax:success", (ev) => {
      enableForm(form);
    });
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
