import { onceEach } from "./once.js";

import "./css/spinner.scss";

export const Spinner = {
  start(element) {
    if (element.querySelector(".spinner")) {
      return;
    }

    const screen = document.createElement("div");
    screen.classList.add("spinner");
    screen.innerHTML = `
      <div class="icon is-large spinning-element">
        <i class="fa fa-3x fa-circle-notch"></i>
      </div>`;
    screen.querySelector(".spinning-element").classList.add("fast-spin");

    element.style.position = "relative";
    element.appendChild(screen);
  },

  stop(element) {
    const spinner = element.querySelector(".spinner");
    spinner?.remove();
  },

  stopAll() {
    document.querySelectorAll(".spinner").forEach((e) => {
      this.stop(e.parentElement);
    });
  },

  scan(document) {
    /* Allow for links and buttons to directly start a spin, or a div to contain
     * links, all of which will start a spin.
     */
    const selectors = [
      "a[data-starts-spinner], button[data-starts-spinner]",
      "div[data-starts-spinner] a, div[data-starts-spinner] button",
      "span[data-starts-spinner] a, span[data-starts-spinner] button",
      "form[data-starts-spinner]",
    ].join(", ");

    onceEach(document.querySelectorAll(selectors), "spinner", (triggerElement) => {
      if (!triggerElement.spinInitiator) {
        triggerElement.spinInitiator = true;

        const parentWithSelector = triggerElement.closest("[data-starts-spinner]");
        const targetSelector = parentWithSelector
          ? parentWithSelector.getAttribute("data-starts-spinner")
          : ":not(*)";

        const eventName = triggerElement.tagName === "FORM" ? "submit" : "click";

        triggerElement.addEventListener(eventName, () => {
          document.querySelectorAll(targetSelector).forEach((target) => {
            this.start(target);
          });
        });
      }
    });
  },
};

document.addEventListener("DOMContentLoaded", () => Spinner.scan(document));
document.addEventListener("partial:replace", (ev) => Spinner.scan(ev.container));
