import "./alert.js";
import "./auto_dropdown.js";
import "./auto_submit.js";
import "./breakpoint_events.js";
import "./field_with_errors.js";
import "./flash.js";
import "./font_awesome.js";
import "./growing_textarea.js";
import "./hint.js";
import "./mobile_button.js";
import "./mobile_menu.js";
import "./navigation_select.js";
import "./olark.js";
import "./recaptcha.js";
import "./replace_partial.js";
import "./scroll_hint.js";
import "./show_password_button.js";
import "./single_submit.js";
import "./sortable_table.js";
import "./spinner.js";
import "./stashed_file_ui.js";
import "./tab_set.js";
import "./video_modal.js";
import "./warn_unsaved_changes.js";
import "./warn_unsaved_trigger.js";

/* We need to be able to cancel Rails' UJS event handlers in `warn_unsaved_changes`, etc. */
import "./ujs.js";

/* Expose symbols to RJS on window object. */
import "./rjs_expose.js";

if (process.env.RAILS_ENV === "development") {
  import("./dev_panel.js");
}

import "./css/layout.scss";

require.context("./images", true);
require.context("./images/favicons", true);
