export const Modal = {
  show(selector) {
    const modal = document.querySelector(selector);
    if (!modal) {
      return;
    }

    const keydownListener = document.addEventListener("keydown", (ev) => {
      if (ev.key == "Escape") {
        ev.preventDefault();
        dismiss();
      }
    });

    const dismiss = () => {
      modal.classList.remove("is-active");
      document.removeEventListener("keydown", keydownListener);
    };

    modal
      .querySelectorAll("[data-closes]")
      .forEach((element) => element.addEventListener("click", dismiss));

    modal.classList.add("is-active");
  },
};
