/*
  Installs beforeclose event handler that warns the user if they have edited data that has
  not been saved.

  Using beforeclose opts the page out of the bfcache, which we want to avoid, so the handler
  is only installed when a dirty element exists.

  Elements can be included with the "data-warn-unsaved-changes" attribute.  The element itself (if
  it has an "input" event) and any child INPUT elements will be opted in.
*/

const dirtyInputs = new Set();

const unloadHandler = (ev) => {
  ev.preventDefault();

  /* This message will be ignored by modern browsers */
  const msg = "You have unsaved changes on this page.  If you continue, they will be lost.";
  ev.returnValue = msg;
  return msg;
};

export let currentUnloadHandler = null;

export const registerUnloadHandler = () => {
  currentUnloadHandler = unloadHandler;
  window.addEventListener("beforeunload", unloadHandler, { capture: true });
};

export const unregisterUnloadHandler = () => {
  currentUnloadHandler = null;
  window.removeEventListener("beforeunload", unloadHandler, { capture: true });
};

const inputSelector = [
  "input:not([type=button]):not([type=submit]):not([type=reset])",
  "textarea",
  "select",
].join(", ");

/* installs input watching event handlers on element */
const watchForChanges = (element) => {
  /* Don't double-up event handlers */
  if (element._warnUnsavedOriginalValue) {
    return;
  }

  /* Save original value so we can uninstall warning on undo, etc. */
  element._warnUnsavedOriginalValue = element.value;

  element.addEventListener("input", () => {
    if (element.value === element._warnUnsavedOriginalValue) {
      markClean(element);
    } else {
      markDirty(element);
    }
  });
};

/* Clear the dirty bit on element, as well as any child elements */
export const markClean = (element) => {
  findInputs(element).forEach((e) => dirtyInputs.delete(e));

  if (dirtyInputs.size === 0) {
    unregisterUnloadHandler();
  }
};

export const markDirty = (element) => {
  dirtyInputs.add(element);
  if (dirtyInputs.size === 1) {
    registerUnloadHandler();
  }
};

/* returns elements tagged with data-warn-unsaved-changes, plus any child <input>'s */
const findInputs = (root = document) => {
  /* allow self if not document */
  const self = root instanceof HTMLElement ? [root] : [];
  const roots = Array.from(root.querySelectorAll("[data-warn-unsaved-changes]"));
  const children = Array.from(roots.flatMap((r) => Array.from(r.querySelectorAll(inputSelector))));

  /* de-duplicate */
  return Array.from(new Set([...self, ...roots, ...children])).filter((e) => {
    return e.matches(inputSelector);
  });
};

/* Scans the document for tagged elements, installing event handlers */
const scan = (document) => {
  /* Allow elements that have been removed from the DOM (partial rendering, etc) to be GC'd. */
  const toRemove = Array.from(dirtyInputs.keys()).filter((e) => !document.contains(e));
  toRemove.forEach((e) => markClean(e));

  findInputs().forEach((e) => watchForChanges(e));
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
