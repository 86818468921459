/* This inserts error message DOM elements based on input elements with a data-errors attribute.
 * It expects a data-errors attribute in the shape of:
 *
 *   { "E-mail": ["has been taken", "is too short"] }
 *
 * Messages like: "E-mail has been taken; E-mail is too short" are generated and placed under the
 * closest parent element with a '.field' class (Bulma standard DOM structure).
 *
 * If the element has class "verbatim-error-message", the string is not prefixed with the attribute
 * name. */
import { onceEach } from "./once.js";
import tippy, { sticky } from "tippy.js";
import { escape } from "lodash-es";

export const displayError = (target, content) => {
  tippy(target, {
    content,
    theme: "error",
    allowHTML: true,
    sticky: true,
    plugins: [sticky],
  }).show();
};

const scan = (document) => {
  /* Allow the behavior to be disabled at the form or input level */
  const selector =
    "form:not([data-inline-errors=false]) [data-errors]:not([data-inline-errors=false])";

  onceEach(document.querySelectorAll(selector), "field-with-errors", (input) => {
    const errors = JSON.parse(input.dataset.errors);
    const content = Object.entries(errors)
      .map(([_attributeName, messages]) => messages.map((m) => escape(m)).join("<br>"))
      .flat()
      .join("\n");

    /* Bulma checkboxes are typically contained within a label.
     * Use that if it exists. */
    const target = ["checkbox", "radio"].includes(input.type)
      ? input.closest("label") ?? input
      : input;

    setTimeout(() => {
      displayError(target, content);
    }, 50);
  });
};

document.addEventListener("DOMContentLoaded", () => scan(document));
document.addEventListener("partial:replace", (ev) => scan(ev.container));
