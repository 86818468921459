import { toast as bulmaToast } from "bulma-toast";

export const Flash = {
  animate: true,
  duration: 6000,
  animationLag: 500,
  enableThrottle: true,

  /* This takes a Rails' type alert class (:notice, :error, etc) and returns an appropriate
     Bulma class (info, success, danger) */
  flashToBulmaClass(type) {
    const mapping = {
      notice: "is-info",
      success: "is-success",
      alert: "is-danger",
      warning: "is-warning",
      error: "is-danger",
    };

    return mapping[type];
  },

  hideExisting() {
    document.querySelectorAll(".flash-toast").forEach((toast) => {
      toast.classList.add("is-hidden");
      toast.remove();
    });
  },

  /* Throttles multiple identical messages in sequence */
  isThrottled(message, { type }) {
    if (!Flash.enableThrottle) {
      return false;
    }

    const startClearThrottle = () => {
      this.throttleTimer = setTimeout(() => {
        this.lastFlash = null;
        this.throttleTimer = null;
      }, Flash.duration + Flash.animationLag);
    };

    if (this.lastFlash === JSON.stringify([message, type])) {
      return true;
    } else {
      this.lastFlash = JSON.stringify([message, type]);
      startClearThrottle();
      return false;
    }
  },

  create(message, { type = "notice", duration = Flash.duration } = {}) {
    if (this.isThrottled(message, { type })) {
      return false;
    }

    const animate = Flash.animate ? { in: "fadeInDown", out: "fadeOutUp" } : null;

    bulmaToast({
      message,
      type: `flash-toast ${this.flashToBulmaClass(type)} no-print`,

      duration,
      position: "top-center",

      dismissible: false,
      closeOnClick: true,

      pauseOnHover: true,
      animate,
    });
  },

  notice(message, args = {}) {
    this.create(message, { type: "notice", ...args });
  },

  success(message, args = {}) {
    this.create(message, { type: "success", ...args });
  },

  error(message, args = {}) {
    this.create(message, { type: "error", ...args });
  },

  ifSuccess(predicate, successMessage, errorMessage, args = {}) {
    if (predicate) {
      this.success(successMessage, args);
    } else {
      if (errorMessage) {
        this.error(errorMessage, args);
      }
    }
  },

  modelUpdated(modelName, wasSuccessful) {
    const messages = {
      success: modelName ? `${modelName} updated!` : "Updated successfully!",
      error: modelName ? `${modelName} failed to update` : "Failed to update",
    };

    this.ifSuccess(wasSuccessful, messages.success, messages.error);
  },
};

/* This takes flashes rendered in the document, and converts them to toasts. */
const convertFlashDataToToasts = () => {
  const flashDataElements = document.querySelectorAll("#flash-data [data-flash]");
  flashDataElements.forEach((flashElement) => {
    if (!flashElement.getAttribute("data-processed")) {
      flashElement.setAttribute("data-processed", true);
      Flash.create(flashElement.dataset.message, {
        type: flashElement.dataset.flash,
      });
    }
  });
};

/* The layout includes a dataset to embed the flashes in the HTML.  Extract them on load */
document.addEventListener("DOMContentLoaded", () => {
  convertFlashDataToToasts();
});
